<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">{{ $route.query.name }}</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <pullrefresh
          :disabled="true"
          @onLoad="onLoad"
          ref="pull"
          v-show="videoList.length > 0"
        >
          <div class="video-list">
            <div v-for="item in videoList" :key="item.id">
              <div v-if="advList.length > 0 && item.adv" class="l_adv">
                <AdvSwiper :advList="item.adv" />
              </div>
              <videoListMore :item="item" v-else />
            </div>
          </div>
        </pullrefresh>
        <van-loading
          type="spinner"
          color="#f21313"
          v-show="videoList && videoList.length == 0 && !noData"
        />
        <nodata v-show="noData" />
      </div>
    </div>
    <!-- <div class="backTop" v-if="scrollTop > 300" @click="backTop"></div> -->
  </div>
</template>
<script>
import videoListMore from "../widget/videoListMore.vue";
import pullrefresh from "@/components/PullRefresh/index.vue";
import AdvSwiper from "@/components/AdvSwiper/index.vue";
import { rankList, iconList } from "@/api/app.js";
import { getAdItem, AdType } from "@/utils/getAdv.js";
export default {
  components: {
    videoListMore,
    pullrefresh,
    AdvSwiper,
  },
  data() {
    return {
      videoList: [], //视频列表
      oldVideoList: [],
      advList: [],
      pageNum: 1, //页码
      noData: false, //暂无数据
      scrollTop: 0,
    };
  },
  // watch: {
  //   $route(to) {
  //     window.removeEventListener('scroll', this.scrollToTop, true)
  //     if (to.path == "/rankMoreVideo") {
  //       window.addEventListener('scroll', this.scrollToTop, true)
  //     }
  //   },
  // },
  created() {
    this.advList = getAdItem(AdType.content2x2);
    if (this.$route.query.name == "最新上传") {
      this.getList();
    } else if (this.$route.query.name == "周热度榜") {
      this.getRankList("week");
    } else {
      this.getRankList("month");
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop, true)
  },
  methods: {
    async getRankList(type) {
      //获取排行榜接口数据
      let req = {
        pageNum: this.pageNum,
        pageSize: 20,
        period: type,
      };
      let ret = await this.$Api(rankList, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        if (this.advList.length > 0) {
          this.oldVideoList = this.oldVideoList.concat(list);
          let advNum = Math.floor(this.oldVideoList.length / 4);
          this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
          let advLength = this.advList.length;
          for (let i = 0; i < advNum; i++) {
            let advItem = this.advList[i % advLength];
            this.videoList.splice(5 * i + 4, 0, { adv: [advItem] });
          }
        } else {
          this.videoList = this.videoList.concat(list);
        }
        if (list.length < 20) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    async getList() {
      //通过接口得到最新上传视频列表
      let req = {
        isVip: true,
        pageNum: this.pageNum,
        pageSize: 20,
        type: "new",
      };
      let ret = await this.$Api(iconList, req);
      if (!this.$refs.pull) return;
      this.$refs.pull.loading = false;
      if (ret.code == 200) {
        let list = ret.data.mediaInfos ? ret.data.mediaInfos : [];
        if (this.advList.length > 0) {
          this.oldVideoList = this.oldVideoList.concat(list);
          let advNum = Math.floor(this.oldVideoList.length / 4);
          this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
          let advLength = this.advList.length;
          for (let i = 0; i < advNum; i++) {
            let advItem = this.advList[i % advLength];
            this.videoList.splice(5 * i + 4, 0, { adv: [advItem] });
          }
        } else {
          this.videoList = this.videoList.concat(list);
        }
        if (list.length < 20) {
          this.$refs.pull.finished = true;
        } else {
          this.$refs.pull.finished = false;
        }
        if (this.pageNum == 1 && list.length == 0) {
          this.noData = true;
        }
      }
    },
    onLoad() {
      this.pageNum++;
      if (this.$route.query.name == "最新上传") {
        this.getList();
      } else if (this.$route.query.name == "周热度榜") {
        this.getRankList("week");
      } else {
        this.getRankList("month");
      }
    },
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    // backTop () {
    //     let dom = document.querySelector(".section");
    //     let timer = setInterval(() => {
    //       let ispeed = Math.floor(-this.scrollTop / 5)
    //       dom.scrollTop = dom.scrollTop = this.scrollTop + ispeed
    //       if (this.scrollTop === 0) {
    //         clearInterval(timer)
    //       }
    //     }, 16)
    // },
    scrollToTop () {
      let dom = document.querySelector(".section");
      this.scrollTop = dom.scrollTop
    }
  },
};
</script>
<style lang="scss" scoped>
.search-all {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.search-box {
  padding: 0 16px;
  height: 52px;
  align-items: center;
  background: $vermillion;
}
.left {
  width: 22px;
  height: 22px;
  background: url("../../assets/png/back_icon.png") center center no-repeat;
  background-size: 100%;
}
.center {
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
}
.right {
  width: 22px;
  height: 30px;
}
.section {
  height: calc(100% - 52px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 10px 5px 30px;
  box-sizing: border-box;
}
.video-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.l_adv {
  width: 178px;
  height: 118px;
  padding: 6px 0 6px 6px;
  box-sizing: border-box;
  overflow: hidden;
  .adv {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
  }
  /deep/ .swiper-container,
  /deep/ .swiperSlide {
    width: 100%;
    height: 100%;
  }
}
.backTop {
  position: fixed;
  right: 8%;
  bottom: 10%;
  width: 50px;
  height: 50px;
  background: url("../../assets/png/backTop.png");
  background-size: 100% 100%;
  z-index: 3;
}
</style>
