<template>
  <div class="video-cover" @click="goFullPlay(item)">
    <div class="top">
      <ImgDecypt class="cover-img" :src="item.videoCover" />
      <div class="video-info flex-between">
        <div class="left">
          <span class="left-play"></span>{{ item.watchTimes | watchCount }}
        </div>
        <div class="right">{{ item.playTime | videotime }}</div>
      </div>
      <!-- <div class="vip">
        <span v-show="item.isVip">VIP</span>
        <span class="money" v-show="!item.isVip"></span>
      </div> -->
      <div class="vip" v-if="item.isVip">
        <span>VIP</span>
      </div>
      <div v-else>
        <div v-if="item.price == 0"></div>
        <div class="vip" v-else>
          <span class="money"></span>
        </div>
      </div>
    </div>
    <div class="title title-top">
      {{ item.content }}
    </div>
  </div>
</template>
<script>
import { jumpVideo } from "@/utils/index.js";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    goFullPlay(item) {
      jumpVideo(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.video-cover {
  width: 170px;
  margin-top: 6px;
  margin-left: 7px;
  margin-right: 5px;
}
.top {
  width: 100%;
  font-size: 0;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}
.cover-img {
  width: 100%;
  height: 96px;
}
.video-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  align-items: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  padding: 0 6.3px;
  box-sizing: border-box;
  font-size: 8px;
  color: #ffffff;
}
.left-play {
  display: inline-block;
  width: 8.9px;
  height: 9.4px;
  background: url("../../assets/png/play_icon.png") center center no-repeat;
  background-size: 100%;
}
.vip {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  color: #ffffff;
  border-bottom-left-radius: 6px;
  font-size: 11px;
  font-weight: bold;
  background: $vermillion;
}
.money {
  display: inline-block;
  width: 11.2px;
  height: 11.2px;
  background: url("../../assets/png/money_icon.png") center center no-repeat;
  background-size: 100%;
  vertical-align: middle;
}
.title {
  width: 160px;
  justify-content: space-between;
}
.title-top {
  font-size: 12px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.tag > span {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 6px;
  background: #e1e0e1;
  font-size: 10px;
  margin-right: 15px;
  color: #515151;
}
</style>
